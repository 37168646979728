import React from 'react';
import './App.css';

import img1 from './static/1.png';
import img2 from './static/2.png';
import img3 from './static/3.png';
import img4 from './static/4.png';
import img5 from './static/5.png';

function App() {
  return (
    <div className="App">
      <img src={img1} className="img"></img>
      <img src={img2} className="img"></img>
      <img src={img3} className="img"></img>
      <img src={img4} className="img"></img>
      <img src={img5} className="img" onClick={testSchname.bind(this)}></img>
      <a className='link' href='http://beian.miit.gov.cn'>yixiujieyou.com 京ICP备2021004401号</a>
    </div>
  );
}

function testSchname () {
  window.location.href = 'weixin://dl/business/?t=CmqDe5OzS8e'
}

export default App;
